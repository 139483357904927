<template>
  <div class="fracpack-open">
    <div class="fracpack-open__image">
      <div
        class="fracpack-open__bgImage"
        v-bind:style="{ backgroundImage: 'url(' + current.asset.img_front + ')' }"
      />
      <ImageResize
        class="fracpack-open__imageResize"
        :src="current.asset.img_front"
        :alt="current.asset.img_alt"
        type="frac_pack_asset"
      />
    </div>

    <div class="fracpack-open__cardInfo">
      <div class="headline-small">
        {{ current.asset.associated_players[0].name }}
      </div>
      <div class="body-text-x-large mt-s8">
        {{ current.asset.name }}
      </div>
    </div>

    <div class="fracpack-open__cardValues">
      <section>
        <h1>
          {{ $t('frac_pack.you_ve_received') }}
        </h1>
        <p>
          {{ receivedValue }}
        </p>
      </section>
      <section>
        <h1>
          {{ $t('frac_pack.asset_price') }}
        </h1>
        <p>
          {{ assetTotalPrice }}
        </p>
      </section>
    </div>

    <div class="fracpack-open__buttons">
      <ButtonV2
        @onClick="next"
        :inactive="isLoading"
        testId="btn-primary"
        :label="
          currentAsset !== fracPack.assets.length - 1
            ? $t('frac_pack.next_card')
            : $t('frac_pack.close')
        "
        :size="isSmallMobileDevice ? 'medium' : 'large'"
        wide
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ImageResize } from '@/components/misc';
import ButtonV2 from '@/stories/misc/ButtonV2';
import WalletQuery from '@/graphql/queries/Wallet.gql';

export default {
  name: 'PackOpen',
  components: {
    ImageResize,
    ButtonV2,
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'FRAC_PACK_VIEWED',
    });
  },

  data() {
    return {
      isLoading: false,
      currentAsset: 0,
    };
  },

  props: {
    fracPack: Object,
  },

  computed: {
    current() {
      return this.fracPack.assets[this.currentAsset];
    },
    receivedValue() {
      const assetPrice = this.numberFormat(
        this.$big(this.current.amount).times(this.current.asset.price_usd),
        2,
        false,
        true,
      );
      const assetPercent = this.$big(this.current.amount).gte(0.0001)
        ? this.numberFormat(
            this.$big(this.current.amount).times(100),
            2,
            false,
            true,
          )
        : '<0.01';
      return `${assetPrice} (${assetPercent}%)`;
    },
    assetTotalPrice() {
      return this.numberFormat(this.current.asset.price_usd, 2, false, true);
    },
  },

  methods: {
    ...mapActions('api', ['refreshPageData']),
    ...mapActions('pack', ['resetPack']),
    
    async next() {
      if (this.currentAsset === this.fracPack.assets.length - 1) {
        try {
          this.isLoading = true;
          await this.refreshPageData({
            $apollo: this.$apollo,
            query: WalletQuery,
            variables: {
              chartEndTimeSeconds: parseInt(new Date().getTime() / 1000),
            },
          });
          this.resetPack();
          this.hideModal();
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }

        return;
      }

      this.currentAsset = this.currentAsset + 1;
    },
  },
};
</script>

<style scoped>
.fracpack-open {
  @apply flex flex-col;
  box-sizing: border-box;
  overflow: auto;
  width: auto;
}

.fracpack-open__image {
  @apply w-full flex items-center justify-center p-s20 flex-1 overflow-hidden relative;
  position: relative;
}

.fracpack-open__bgImage {
  @apply opacity-40 absolute top-0 left-0 w-full h-full;
  z-index: calc(var(--z-index-image) - 1);
  background-position: center;
  background-size: cover;
  transform: scale(1.75);
}

.fracpack-open__imageResize {
  z-index: 2;
}

.fracpack-open__cardInfo {
  @apply my-s16 px-s20;
}

.fracpack-open__cardValues {
  @apply w-full flex justify-between items-center px-s20;
}

.fracpack-open__cardValues h1 {
  @apply body-text-large text-text-inactive-2;
}

.fracpack-open__cardValues p {
  @apply subheadline-medium;
}

.fracpack-open__buttons {
  @apply flex w-full px-s20 my-s24 ;
}

@screen start-xl {
  .fracpack-open {
    height: min(calc(100vh - 75px), 768px);
  }
}
</style>
